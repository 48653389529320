import React from "react";
import { useStep } from "../../../contexts/stepContexts";

const EidSalePricing = () => {
  const { showEidMarketing } = useStep();

  if (!showEidMarketing) return null;

  return (
    <div className="w-full max-w-5xl mx-auto mb-12 mt-5">
      <div className="bg-gradient-to-r from-green-500/10 to-green-500/5 border border-green-500/20 rounded-lg p-6">
        <div className="flex flex-col md:flex-row items-center justify-between gap-6">
          <div className="text-center md:text-left">
            <div className="inline-block bg-green-500/20 px-4 py-1 rounded-full mb-4">
              <span className="text-green-400 font-medium">🎉 Специальное предложение к Курбан-байрам!</span>
            </div>
            <h3 className="text-2xl font-bold text-white mb-2">Скидка 25% на все пакеты</h3>
            <p className="text-gray-300">
              Предложение ограничено по времени - Используйте код{" "}
              <span className="bg-green-500/20 text-green-400 font-mono px-3 py-1 rounded">EID25</span>
            </p>
          </div>
          <div className="flex items-center gap-6">
            <div className="text-center">
              <div className="text-3xl font-bold text-green-400">25%</div>
              <div className="text-sm text-gray-400">Скидка</div>
            </div>
            <div className="text-center">
              <div className="text-3xl font-bold text-green-400">7</div>
              <div className="text-sm text-gray-400">Дней осталось</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EidSalePricing; 