import React from "react";
import { useStep } from "../../../contexts/stepContexts";

const EidSaleBanner = () => {
  const { showEidMarketing } = useStep();

  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      var successful = document.execCommand("copy");
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }
    document.body.removeChild(textArea);
  }

  function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        alert("Код скопирован успешно");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  if (!showEidMarketing) return null;

  return (
    <div className="w-full bg-gradient-to-r from-green-500/10 to-green-500/5 border-b border-green-500/20 -mt-20 mb-10">
      <div className="container mx-auto px-6 py-3">
        <div className="flex flex-col md:flex-row items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <div className="bg-green-500/20 px-4 py-1 rounded-full">
              <span className="text-green-400 font-medium">🎉 Распродажа к празднику Курбан-байрам</span>
            </div>
            <div className="hidden md:flex items-center gap-2">
              <span className="text-gray-400">Получите</span>
              <span className="text-2xl font-bold text-green-400">25% СКИДКУ</span>
              <span className="text-gray-400">на все пакеты</span>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <span className="text-gray-400">Используйте код:</span>
              <span className="bg-green-500/20 text-green-400 px-3 py-1 rounded font-mono">EID25</span>
              <button 
                onClick={() => copyTextToClipboard("EID25")}
                className="text-gray-400 hover:text-green-400 transition-colors"
              >
                Копировать
              </button>
            </div>
            <div className="hidden md:flex items-center gap-2">
              <span className="text-gray-400">Предложение ограничено по времени</span>
              <span className="text-green-400">•</span>
              <span className="text-gray-400">осталось 7 дней</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EidSaleBanner; 