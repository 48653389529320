import React, { useState, useEffect } from "react";
import { useStep } from "../../../contexts/stepContexts";

const EidSaleSection = () => {
  const { showEidMarketing } = useStep();
  const [timeLeft, setTimeLeft] = useState(getTimeRemaining());

  useEffect(() => {
    if (timeLeft.total > 0) {
      const timer = setTimeout(() => {
        setTimeLeft(getTimeRemaining());
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [timeLeft]);

  function getTimeRemaining() {
    const today = new Date();
    const eidDate = new Date(today);
    eidDate.setDate(today.getDate() + 7);
    eidDate.setHours(23, 59, 59, 999);
    const total = eidDate - new Date();

    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const seconds = Math.floor((total / 1000) % 60);

    return {
      total,
      days,
      hours,
      minutes,
      seconds
    };
  }

  function copyToClipboard() {
    const el = document.createElement("textarea");
    el.value = "EID25";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    alert("Код EID25 скопирован в буфер обмена!");
  }

  if (!showEidMarketing) return null;

  return (
    <div className="relative overflow-hidden bg-[#26303C] py-20">
      {/* Background Pattern */}
      <div className="absolute inset-0 opacity-10">
        <div className="absolute inset-0" style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
        }} />
      </div>

      <div className="container mx-auto px-4 relative">
        <div className="max-w-5xl mx-auto">
          {/* Main Content */}
          <div className="flex flex-col lg:flex-row items-center gap-8">
            {/* Left Side - Text Content */}
            <div className="flex-1 text-center lg:text-left">
              <div className="inline-block bg-green-500/10 rounded-full px-4 py-2 mb-6">
                <span className="text-green-400 font-semibold">Ограниченное предложение</span>
              </div>
              
              <h2 className="text-4xl lg:text-5xl font-bold text-white mb-6">
                Специальное предложение к Курбан-байрам! 🎉
              </h2>
              
              <p className="text-xl text-gray-300 mb-8">
                Получите <span className="text-green-400 font-bold">25% скидку</span> на все пакеты
              </p>
              
              <div className="flex items-center justify-center lg:justify-start gap-4 mb-8">
                <span className="text-2xl font-mono bg-green-500/20 text-green-400 px-4 py-2 rounded-lg">
                  EID25
                </span>
                <button
                  onClick={copyToClipboard}
                  className="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded-lg font-semibold transition-colors duration-200"
                >
                  Копировать код
                </button>
              </div>

              <div className="text-gray-400 space-y-2">
                <p className="flex items-center gap-2">
                  <span className="text-green-400">✓</span> Специальная скидка к празднику
                </p>
                <p className="flex items-center gap-2">
                  <span className="text-green-400">✓</span> Ограниченное время действия
                </p>
                <p className="flex items-center gap-2">
                  <span className="text-green-400">✓</span> Действует на все пакеты
                </p>
              </div>
            </div>

            {/* Right Side - Countdown */}
            <div className="flex-1 w-full max-w-md">
              <div className="bg-[#1a1f27] rounded-2xl p-8 border border-green-500/20">
                <h3 className="text-xl font-semibold text-white mb-6 text-center">
                  Осталось 7 дней!
                </h3>
                
                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-[#26303C] rounded-xl p-4 text-center border border-green-500/10">
                    <div className="text-3xl font-bold text-green-400 mb-1">{timeLeft.days}</div>
                    <div className="text-sm text-gray-400">Дней</div>
                  </div>
                  <div className="bg-[#26303C] rounded-xl p-4 text-center border border-green-500/10">
                    <div className="text-3xl font-bold text-green-400 mb-1">{timeLeft.hours}</div>
                    <div className="text-sm text-gray-400">Часов</div>
                  </div>
                  <div className="bg-[#26303C] rounded-xl p-4 text-center border border-green-500/10">
                    <div className="text-3xl font-bold text-green-400 mb-1">{timeLeft.minutes}</div>
                    <div className="text-sm text-gray-400">Минут</div>
                  </div>
                  <div className="bg-[#26303C] rounded-xl p-4 text-center border border-green-500/10">
                    <div className="text-3xl font-bold text-green-400 mb-1">{timeLeft.seconds}</div>
                    <div className="text-sm text-gray-400">Секунд</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EidSaleSection; 